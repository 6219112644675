import { createEnv } from '@t3-oss/env-nextjs'
import { z as s } from 'zod'

export const env = createEnv({
  client: {
    NEXT_PUBLIC_GOOGLE_MAPS_API_KEY: s.string(),
    NEXT_PUBLIC_GOOGLE_MAPS_ID: s.string(),
    NEXT_PUBLIC_GOOGLE_CONVERSION_ID: s.string(),
    NEXT_PUBLIC_HOTJAR_ID: s.string(),
    NEXT_PUBLIC_APP_URL: s.union([
      s.literal('https://www.dwelinn.com'),
      s.literal('https://dev.dwelinn.com'),
      s.literal('http://localhost:3000')
    ])
  },

  runtimeEnv: {
    NEXT_PUBLIC_GOOGLE_CONVERSION_ID:
      process.env.NEXT_PUBLIC_GOOGLE_CONVERSION_ID,
    NEXT_PUBLIC_HOTJAR_ID: process.env.NEXT_PUBLIC_HOTJAR_ID,
    NEXT_PUBLIC_APP_URL: process.env.NEXT_PUBLIC_APP_URL,
    NEXT_PUBLIC_GOOGLE_MAPS_API_KEY:
      process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
    NEXT_PUBLIC_GOOGLE_MAPS_ID: process.env.NEXT_PUBLIC_GOOGLE_MAPS_ID
  }
})
