import { Button } from '@mantine/core';
import { cn } from '~/utils/css';

export const ButtonExtension = Button.extend({
  defaultProps: { size: 'md', radius: 'md' },
  classNames: {
    root: cn(
      "data-[size='xl']:rounded-xl data-[size='lg']:rounded-xl data-[size='md']:rounded-[10px]"
    ),
    loader: cn('[&>span]:animate-spin'),
    label: cn('data-[loading]:opacity-0')
  }
});
